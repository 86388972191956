








































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import { KodeEvaluationSession } from '@/store/kode/types'
import { Action } from 'vuex-class'
import { DELETE_KODE_EVALUATION_SESSION, GET_ALL_KODE_EVALUATION_SESSION } from '@/store/kode/actions'
import { SimpleServerResponse } from '@/store/types'
import TextCreatorForSubmitSuccess from '@/components/forms/getHeadlineAndInfoForSubmitSuccess'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import { SortingInfo } from '@/store/commonFormInput/types'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
@Component({
  components: { LoadingSpinner, SearchResult, ResultsPage, ClickableIcon, ConfirmationModal }
})
export default class KodeEvaluationSessionOverview extends Vue {
  @Action(GET_ALL_KODE_EVALUATION_SESSION)
  getAllSessions: () => Promise<Array<KodeEvaluationSession>>

  @Action(DELETE_KODE_EVALUATION_SESSION)
  deleteSessionCall: (sessionId: string) => Promise<SimpleServerResponse>

  private evaluationSessions: KodeEvaluationSession[] = []
  private bookmarkedForDelete = ''
  private isLoading = true
  private textCreator: TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>
  private feedbackHeadline = ''
  private errorMessage = ''
  private showErrorMessage = false
  private maxPerColumn = 2

  get sortable (): SortingInfo[] {
    return [
      { key: 'name', label: this.$i18n.t('kode-evaluation.name').toString() },
      { key: 'participantCount', label: this.$i18n.t('kode-evaluation.participants').toString() },
      { key: 'modified', label: this.$i18n.t('kode-evaluation.modified-date').toString(), isDate: true }
    ]
  }

  mounted (): void {
    this.getAllSessions().then(data => {
      this.evaluationSessions = data
      this.evaluationSessions = this.evaluationSessions.map(session => ({ ...session, participantCount: session.participants.length }))
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.isLoading = false
    })
  }

  showConfirmationModal (sessionId: string): void {
    this.bookmarkedForDelete = sessionId
    this.$bvModal.show('confirmation-modal-delete-session')
  }

  deleteSession (): void {
    this.$root.$emit('load')
    this.deleteSessionCall(this.bookmarkedForDelete).then(() => {
      const index = this.evaluationSessions.findIndex(session => {
        return session.id === this.bookmarkedForDelete
      })
      if (index !== -1) {
        this.evaluationSessions.splice(index, 1)
      }
    }).catch((error: SimpleServerResponse) => {
      this.textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'kode-evaluation.delete.')
      this.feedbackHeadline = this.textCreator.getHeadline()
      this.$root.$emit('alert', this.feedbackHeadline, '', true)
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }
}
